<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState} from "vuex";
import LayoutLoose from "@/router/layouts/LayoutLoose";


export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    LayoutLoose,
    ProfileStatus: () => import('@/components/lazy/profile/ProfileStatus'),
  },
  validations: {

  },
  methods: {

  },
  created() {

  },
  data() {
    return {
      cftools_id: this.$route.params.cftools_id
    }
  }
};
</script>

<template>
  <LayoutLoose>
    <ProfileStatus :cftools_id="cftools_id" />
  </LayoutLoose>
</template>